<template>
    <div class="route-stop-item media">
        <img src="/assets/images/route-stop-item.svg" :alt="name" class="mr-3" />
        <div class="media-body text-primary-dark">
            <p class="font-weight-bold mb-1">{{ name }}</p>
            <p class="mb-0">{{ address }}</p>
        </div>
        <div class="media-control dropdown">
            <button type="button" class="btn btn-link dropdown-toggle dropdown-toggle-icon" data-toggle="dropdown">
                <fa icon="ellipsis-v" />
            </button>
            <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <a class="dropdown-item" href="#">Something else here</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'RouteStopItem',
        props: {
            name: String,
            address: String,
        }
    }
</script>
