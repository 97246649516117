<template>
    <div class="route-search-box">
        <div class="flex-shrink-1">
            <button type="button" class="btn border bg-white text-primary" @click="onAdd">
                <fa icon="plus" />
            </button>
        </div>
        <div class="flex-grow-1 px-2">
            <div class="route-search-input input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <fa icon="search" />
                    </div>
                </div>
                <input type="text" class="form-control pl-0" placeholder="Search" />
            </div>
        </div>
        <div class="flex-shrink-1">
            <button type="button" class="btn btn-primary btn-rounded px-4">Add</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'RouteSearchBox',
        methods: {
            onAdd() {
                this.$emit('add');
            }
        }
    }
</script>
